export const testServer = true

export const bookhara = {
	API_URL: testServer ? 'https://api-test.bookhara.uz/api' : 'https://api.bookhara.uz/api',
	EMAIL: 'web@bookhara.uz',
	PASSWORD: 'yHea7LVE',
}

export const insurance = {
	API_URL: testServer ? 'https://insurance-test.bookhara.uz/api' : 'https://insurance.bookhara.uz/api',
	EMAIL: 'frontend@bookhara.uz',
	PASSWORD: testServer ? 'MpAruypXuY5PUh5W' : 'egNfRKRYeeDECwa9',
}

export const transfers = {
	API_URL: testServer ? 'https://transfers-test.bookhara.uz/api' : 'https://transfers.bookhara.uz/api',
	EMAIL: testServer ? 'frontend-test@bookhara.uz' : 'frontend@bookhara.uz',
	PASSWORD: testServer ? 'tYcntc949bi9' : 'CdD2j/4gr4bVCubn',
	TOKEN: testServer ? '3|SZtRHaos0fMhV6s7etSTHWs7FTitybS87Zuh8ZW0' : '3|XdafJDW3VSfrlhzC5owqKQqWeFL4YncyJgqrnq4m',
}

export const trains = {
	API_URL: testServer ? 'https://gateway.bookhara.uz/api/v1/' : '',
	USERNAME: 'timur_gilmanov',
	PASSWORD: testServer ? 'uZa5^$I0G057' : 'uZa5^$I0G057',
}

export default {
	srcDir: 'src',

	server: {
		host: '0.0.0.0',
		port: testServer ? 3001 : 3000,
	},

	// Global page headers: https://go.nuxtjs.dev/config-head
	head: {
		meta: [
			{ charset: 'utf-8' },
			{ name: 'viewport', content: 'width=device-width, initial-scale=1' },
			{ hid: 'description', name: 'description', content: '' },
			{ name: 'format-detection', content: 'telephone=no' }
		],
		link: [
			{ rel: 'icon', href: '/design/fav.svg' }
		]
	},

	// Global CSS: https://go.nuxtjs.dev/config-css
	css: [
		'~/assets/css/app.css',
	],

	tailwindcss: {
		configPath: '~~/tailwind.config.js',
		viewer: false,
	},

	// Plugins to run before rendering page: https://go.nuxtjs.dev/config-plugins
	plugins: [
		'~/plugins/axios.js',
		'~/plugins/mixins.js',
		'~/plugins/vuelidate.js',
		'~/plugins/vue-js-modal.js',
		{src: '~/plugins/directives.js', mode: 'client'},
		{src: '~/plugins/v-tooltip.js', mode: 'client'},
	],

	// Auto import components: https://go.nuxtjs.dev/config-components
	//components: true,

	// Modules for dev and build (recommended): https://go.nuxtjs.dev/config-modules
	buildModules: [
		'@nuxt/postcss8',
		'@nuxtjs/composition-api/module',
		'@nuxtjs/router',
		'@nuxtjs/device',
	],

	// Modules: https://go.nuxtjs.dev/config-modules
	modules: [
		'@nuxtjs/axios',
		'@nuxtjs/i18n',
		'@nuxtjs/proxy',
		'@nuxtjs/toast',
		'@nuxtjs/recaptcha',
		'@nuxtjs/sentry',
		'cookie-universal-nuxt',
		'portal-vue/nuxt',
	],

	sentry: {
		dsn: testServer ? 'https://5060461d95e8417f931ab6e909094c98@o4504583182548992.ingest.sentry.io/4504583187857408' : 'https://bec9ea319ad843f899767e6a9c7f9e9b@o4504583182548992.ingest.sentry.io/4504586645209088',
		config: {
			beforeBreadcrumb(breadcrumb) {
				if (breadcrumb.category === 'xhr' && /(mc\.yandex\.ru\/webvisor)|(google-analytics\.com)|(jivosite\.com)/.test(breadcrumb.data.url)) {
					return null
				}
				return breadcrumb
			},
		},
	},

	recaptcha: {
		//hideBadge: Boolean, // Hide badge element (v3 & v2 via size=invisible)
		//language: String, // Recaptcha language (v2)
		siteKey: '6Le0NX0eAAAAALKivkJC_WsOFVHH0y_DTEJ8l07x',    // Site key for requests
		version: 2, // Version
		//size: String // Size: 'compact', 'normal', 'invisible' (v2)
	},

	toast: {
		position: 'top-right',
		duration: 5000,
	},

	proxy: {
		'/api': {
			target: bookhara.API_URL,
			changeOrigin: true,
			proxyTimeout: 600000,
			timeout: 600000,
			pathRewrite: {
				'^/api': '/',
			},
			onProxyReq:	function(proxyReq) {
				proxyReq.setHeader('origin', '')
				proxyReq.setHeader('referer', '')
			}
		},
		'/currency-rates': {
			target: 'https://cbu.uz/ru/arkhiv-kursov-valyut/json',
			changeOrigin: true,
			pathRewrite: {
				'^/currency-rates': '/',
			},
			onProxyReq:	function(proxyReq) {
				proxyReq.setHeader('cookie', '')
			},
		},
	},

	i18n: {
		vueI18nLoader: true,
		locales: ['ru', 'uz'],
		defaultLocale: 'ru',
		strategy: 'no_prefix',
		parsePages: false,
		detectBrowserLanguage: {
			useCookie: true,
			cookieKey: 'language',
		},
		vueI18n: '/i18n.js',
	},

	routerModule: {
		path: 'src/router',
		fileName: 'index.js'
	},

	router: {
		prefetchLinks: false,
		middleware: ['redirect', 'route-guard']
	},

	// Axios module configuration: https://go.nuxtjs.dev/config-axios
	axios: {
		// Workaround to avoid enforcing hard-coded localhost:3000: https://github.com/nuxt-community/axios-module/issues/308
		baseURL: '/',
	},

	// Build Configuration: https://go.nuxtjs.dev/config-build
	build: {
		extractCSS: {
			ignoreOrder: true
		},
		loaders: {
			vue: {
				compilerOptions: {
					whitespace: 'condense'
				}
			}
		},
		transpile: [
			'swiper',
		],
		postcss: {
			plugins: {
				tailwindcss: {},
				autoprefixer: {},
			}
		},
		extend(config) {
			config.devtool = 'source-map'
		}
	},

	render: {
		static: {
			maxAge: 1000 * 60 * 60 * 24 * 180 // 180 days
		},
		fallback: false
	}
}
